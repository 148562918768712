import api, {Headers} from '../config/api';

const url = '/geoip';

export const GeoIPAPI = {
  get: async () => {
    const payload = {
      operation: 'validate-ip-address',
      payload  : {
        accountID: localStorage.getItem('accountID'),
      },
    };

    try {
      const res = await api.post(url, payload, {
        headers: Headers,
      });

      console.log('geoips : apires', res.data.statusCode);
      
      return res.data.statusCode == 200;
    } catch (error) {
      console.log(error);
    }
  },
};
