import React, { useEffect, useRef, useState } from 'react';
import { tvShowsApi } from '../../../services/content-apis/tvShows';
import arrowDown from '../../../assets/images/icons/arrow_down.png';
import arrowUp from '../../../assets/images/icons/arrow-up.png';
import styles from '../ContentDetails2.module.css';
import BeginButton from '../../begin-button/begin-button';

import { useNavigate } from 'react-router-dom';

import { FaPlayCircle } from 'react-icons/fa';

import {
  ContentDetails,
  PlaylistItem,
} from '../../../types/local-models/ContentDetails.model';
import { wishlistApi } from '../../../services/content-apis/wishlist';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Loading from '../../Loading/Loading';
import favoriteIcon from '../../../assets/images/icons/favorite.png';
import shareIcon from '../../../assets/images/icons/share.png';
import mixpanel from 'mixpanel-browser';

interface tvSeries {
  seriesID: string;
  seriesName: string;
  seasons: number;
  genres: string;
  runtime: string;
  banner: string;
  overview: string;
  status: string;
  rating: string;
  languages: string;
  countries: string;
  cast: string;
  directors: string;
}

interface ExternalLinks {
  other_databases: Record<string, unknown>;
  social: Record<string, unknown>;
  archived_databases: Record<string, unknown>;
}

interface Season {
  contentURL: string;
  seasonCreatedAt: number;
  seasonExternalLinks: ExternalLinks;
  seasonID: string;
  seasonName: string;
  seasonNumber: number;
  seasonStatus: string;
  seasonUpdatedAt: number;
}

interface ExternalLinksEpisodes {
  other_databases: {
    wikidata: string;
    imdb: string;
  };
  social: {
    tikTok: string;
    twitter: string;
    instagram: string;
    facebook: string;
    youTube: string;
  };
  archived_databases: {
    freebase_id: string;
    freebase_mid: string;
    tvrage_id: string;
  };
}

interface Episode {
  episodeType: string;
  episodeNumber: number;
  episodeTranscoding: string;
  episodeThumbnail: string;
  episodeStatus: string;
  episodeUpdatedAt: number;
  episodeName: string;
  episodeStreamLink: string;
  episodeCreatedAt: number;
  episodeExternalLinks: ExternalLinksEpisodes;
  contentURL: string;
  seasonID: number;
  episodeID: string;
  subscriptions: any[];
  mediaid: string;
  episodeRuntime: number;
  episodeOverview: string;
}

const TvShowDetails: React.FC<ContentDetails> = ({
  contentID,
  setPlayerUrl,
  setPlaylist,
}) => {
  const [isSubscribed, setIsSubscribed] = React.useState<boolean | null>(null);
  const [activeTab, setActiveTab] = React.useState<string>('episodes');
  const [series, setSeries] = React.useState<tvSeries>();
  const [seasons, setSeasons] = React.useState<Season[]>([]);
  const [selectedSeason, setSelectedSeason] = React.useState<string>();
  const [episodes, setEpisodes] = React.useState<Episode[]>([]);

  const [wishlistBtnTxt, setWishlistBtnTxt] = React.useState('Add to List');
  const [wishlistBtnIcon, setWishlistBtnIcon] = React.useState('icon-favorite');
  const [wishlistBtnLoading, setWishlistBtnLoading] = React.useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [loading, setLoading] = React.useState(true);

  const [playlistItems, setPlaylistItems] = React.useState<PlaylistItem[]>([]);
  const [maxHeight, setMaxHeight] = useState('225px');
  const episodesRef = useRef<HTMLDivElement | null>(null);
  const [isOverviewExpanded, setIsOverviewExpanded] = useState(false);

  const navigate = useNavigate();
  const getEpisodes = (seasonID: string) => {
    setPlaylistItems([]); // Clear playlistItems when a new season is selected
    tvShowsApi.getEpisodesBySeason(seasonID).then((episodesList: any) => {
      setEpisodes(episodesList);
      console.log('episode data', episodesList);
      const newPlaylistItems: PlaylistItem[] = [];
      episodesList.forEach((episode: Episode) => {
        newPlaylistItems.push({
          title: episode.episodeName,
          file: episode.episodeStreamLink,
          image: episode.episodeThumbnail,
          isDRM: false,
          mediaid: episode.mediaid || '',
          contentID: contentID,
          videoContentID: episode.episodeID,
          overview: episode.episodeOverview,
          runtime: episode.episodeRuntime,
        });
      });

      // Update the playlistItems and set the playlist
      setPlaylistItems(newPlaylistItems);
      if (typeof setPlaylist !== 'undefined') {
        setPlaylist({
          playlistIndex: 0, // Reset playlistIndex to 0
          PlaylistItems: newPlaylistItems,
        });
      }
    });
  };

  useEffect(() => {
    if (isExpanded && episodesRef.current) {
      setMaxHeight(`${episodesRef.current.scrollHeight}px`);
    } else {
      setMaxHeight('300px');
    }
  }, [isExpanded]);
  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const toggleOverview = () => {
    setIsOverviewExpanded(!isOverviewExpanded);
  };

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    tvShowsApi.getTVShow(contentID, 'tv-show').then((response: any) => {
      localStorage.setItem('tvSeriesData', JSON.stringify(response?.streamLink[0]))
      const casts = response.streamLink[0].seriesCast;
      const castList = casts ? casts.split(' / ').map((cast: string) => cast.trim()) : '';

      //Genre Conversion
      const genres = response.genres;
      const genreList = genres.split(' / ').map((genre: string) => genre.trim());

      //  const episode = response.streamLink[0].seasons[0].episodes; 
      //  const episodeCount = episodes ? episode.length : 0;
      // console.log('Episode Count',episodeCount)

      window.webengage.track('content_page_viewed', {
        'content_name': response.streamLink[0].movieOriginalTitle,
        'content_category': 'Tv Shows',
        'genres_name': genreList,
        'cast': castList,
        'language': response.streamLink[0].movieOriginalLanguage,
        'release_year': response.streamLink[0].movieReleaseDate,
        'duration': response.runtime,
        'season_name': response.streamLink[0].seasons[0].seasonName,
        'season_count': response.streamLink[0].seasons.length,
        'content_rating': response.streamLink[0].rating,
        'tag': response.streamLink[0].movieTags,
        'trivia': response.streamLink[0].overview,
        'event_source': 'Web'
      });
      mixpanel.track('content_page_viewed', {
        'content_name': response.streamLink[0].movieOriginalTitle,
        'content_category': 'Tv Shows',
        'genres_name': genreList,
        'cast': castList,
        'language': response.streamLink[0].movieOriginalLanguage,
        'release_year': response.streamLink[0].movieReleaseDate,
        'duration': response.runtime,
        'season_name': response.streamLink[0].seasons[0].seasonName,
        'season_count': response.streamLink[0].seasons.length,
        'content_rating': response.streamLink[0].rating,
        'tag': response.streamLink[0].movieTags,
        'trivia': response.streamLink[0].overview,
        'event_source': 'Web'
      });
      setIsSubscribed(response.isSubscribed);
      const content = response.streamLink[0];

      setSeries({
        seriesID: content.seriesID,
        seriesName: content.seriesOriginalTitle,
        seasons: content.seasons.length,
        genres: response.genres,
        runtime: response.runtime,
        banner: content.images.find((img: any) => img.imageType === 'cover-hd')
          .imagePath,
        overview: content.seriesOverview,
        status: content.seriesShowStatus,
        rating: content.seriesMaturityRating,
        languages: content.seriesProductionLanguages.join(', '),
        countries: content.seriesProductionCountries.join(', '),
        cast: content.seriesCast,
        directors: content.seriesDirectors,
        //cast: 'John Doe, Jane Doe, John Smith, Jane Smith, Vicky Doe, Vicky Smith, John Vicky, Jane Vicky, Smith Doe, Smith Smith, Doe Doe, Smith Vicky',
      });
      setSeasons(content.seasons);
      setSelectedSeason(content.seasons[0].seasonID);

      getEpisodes(content.seasons[0].seasonID);

      if (content.isAddedToMyList) {
        setWishlistBtnTxt('Remove from List');
        setWishlistBtnIcon('icon-favorite-filled');
      }
    });
  }, [contentID]);

  return (
    <div className={styles.contentDetails}>
      <Loading show={loading} />
      <div className={styles.details}>
        <LazyLoadImage
          className={styles.banner}
          effect="blur"
          src={series?.banner}
          beforeLoad={() => {
            setLoading(true);
          }}
          onLoad={() => {
            setLoading(false);
          }}
        />

        <div className={styles.metadata}>
          <div className={styles.wrapper}>
            <div className={styles.container}>
              <h1>{series?.seriesName}</h1>
              {/* <ul>
                <li>{series?.seasons}</li>
                <li>{series?.genres}</li>
                <li>{series?.runtime}</li>
              </ul> */}
            </div>

            <div className={styles.btnGroup}>
              {isSubscribed && (
                <>
                  <BeginButton
                    preset="link"
                    type="button"
                    className="secondary"
                    to=""
                    datalabel="Play"
                    dataiconstart="icon-play"
                    onClick={() => {
                      setPlayerUrl(episodes[0].episodeStreamLink);

                      if (typeof setPlaylist !== 'undefined') {
                        setPlaylist({
                          playlistIndex:
                            Number(localStorage.getItem(contentID)) || 0,
                          PlaylistItems: playlistItems,
                        });
                      }
                    }}
                  />

                  <BeginButton
                    className="white"
                    datalabel={wishlistBtnTxt}
                    type="button"
                    dataiconstart={wishlistBtnIcon}
                    squared={true}
                    loading={wishlistBtnLoading}
                    onClick={(event) => {
                      event.stopPropagation();

                      setWishlistBtnLoading(true);
                      if (wishlistBtnTxt === 'Remove from List') {
                        wishlistApi.removeFromWishList(
                          contentID,
                          (err, res) => {
                            setWishlistBtnLoading(false);
                            if (err) {
                              console.log(err);
                            } else {
                              setWishlistBtnTxt('Add to List');
                              setWishlistBtnIcon('icon-favorite');
                            }
                          }
                        );
                      } else {
                        wishlistApi.addToWishList(
                          contentID,
                          'tvseries',
                          (err, res) => {
                            setWishlistBtnLoading(false);
                            if (err) {
                              console.log(err);
                            } else {
                              setWishlistBtnTxt('Remove from List');
                              setWishlistBtnIcon('icon-favorite-filled');
                            }
                          }
                        );
                      }
                    }}
                  />
                </>
              )}
              {!isSubscribed && isSubscribed !== null && (
                <BeginButton
                  className="primary"
                  datalabel="Subscribe Now"
                  to=""
                  type="button"
                  dataiconstart="icon-locked"
                  squared={true}
                  tall={true}
                  onClick={(event) => {
                    event.stopPropagation();
                    localStorage.setItem(
                      'lastLocation',
                      `/tv-shows/${contentID}`
                    );
                    navigate(
                      `/new-available-subscriptions/tv-shows/${contentID}`
                    );
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </div>

      <div className={styles.overview}>
        <div className={styles.wrapper}>
          <div className={`${styles.container} ${styles.movies}`}>
            <div className={styles.status}>
              <div className={styles.overviewDetails}>
                <ul>
                  <li>{series?.status}</li>

                  {series?.rating && (
                    <li>
                      <div className={styles.rating}>{series?.rating}</div>
                    </li>
                  )}
                  <li>{series?.seasons} Seasons</li>
                </ul>
              </div>
              <p>{series?.overview}</p>
            </div>
            <div className={styles.tabsContainer}>
              <div className={styles.episodeTabsDiv}>
                <a
                  className={`${styles.tab} ${activeTab === 'episodes' ? styles.active : ''
                    }`}
                  onClick={(event) => {
                    event.stopPropagation();
                    setActiveTab('episodes');
                  }}
                >
                  Episodes
                </a>
                {/* <a
                  className={`${styles.tab} ${
                    activeTab === 'moreLikeThis' ? styles.active : ''
                  }`}
                  onClick={(event) => {
                    event.stopPropagation();
                    setActiveTab('moreLikeThis');
                  }}
                >
                  More Like This
                </a> */}
                <a
                  className={`${styles.tab} ${activeTab === 'details' ? styles.active : ''
                    }`}
                  onClick={(event) => {
                    event.stopPropagation();
                    setActiveTab('details');
                  }}
                >
                  Details
                </a>
              </div>

              <div className={styles.seasons}>
                {
                  <select
                    value={selectedSeason}
                    onChange={(event) => {
                      setSelectedSeason(event.target.value);
                      getEpisodes(event.target.value);
                    }}
                  >
                    {seasons.map((season, index) => {
                      return (
                        <option
                          key={index}
                          value={season.seasonID}
                        >
                          {season.seasonName}
                        </option>
                      );
                    })}
                  </select>
                }
              </div>
            </div>
            <div className={styles.additionalDetails}>
              {activeTab === 'episodes' && (
                <>
                  <div className={styles.episodesContainer}>
                    <div
                      className={`${styles.episodes} ${isExpanded ? styles.expanded : ''
                        }`}
                      style={{ maxHeight }}
                      ref={episodesRef}
                    >
                      {episodes.map((episode, index) => (
                        <div
                          className={styles.episode}
                          key={index}
                          onClick={() => {
                            if (
                              typeof setPlayerUrl !== 'undefined' &&
                              typeof setPlaylist !== 'undefined'
                            ) {
                              isSubscribed &&
                                setPlayerUrl(episode.episodeStreamLink);
                              console.log('link', episode.episodeStreamLink);
                              console.log('index', index);
                              console.log('playlistitems', playlistItems);
                              isSubscribed &&
                                setPlaylist({
                                  playlistIndex: index,
                                  PlaylistItems: playlistItems,
                                });
                            }
                          }}
                        >
                          <div className={styles.episodeThumbnail}>
                            <img
                              src={episode.episodeThumbnail}
                              alt={episode.episodeName}
                            />

                            {isSubscribed && (
                              <div className={styles.episodePlay}>
                                <FaPlayCircle />
                              </div>
                            )}

                            {isSubscribed != null && !isSubscribed && (
                              <div className={styles.episodeLocked}>
                                <i className="icon-locked" />
                              </div>
                            )}
                          </div>

                          <div className={styles.episodeDetails}>
                            <div className={styles.episodeIndex}>
                              {episode.episodeName}
                            </div>

                            {episode.episodeRuntime && (
                              <div className={styles.episodeTime}>
                                {episode.episodeRuntime} min
                              </div>
                            )}
                            <div className={styles.episodeIcons}>
                              {/* <img src={favoriteIcon} alt="Favorite" /> */}
                              {/* <img src={shareIcon} alt="Share" /> */}
                            </div>

                            <div className={styles.episodeDes}>
                              {episode?.episodeOverview && (
                                <p className={styles.episodeDestext}>
                                  {isOverviewExpanded
                                    ? episode?.episodeOverview
                                    : `${episode?.episodeOverview?.substring(
                                      0,
                                      150
                                    )}...`}
                                  {series?.overview &&
                                    series?.overview.length > 150 && (
                                      <span
                                        onClick={(event) => {
                                          event.stopPropagation();
                                          toggleOverview();
                                        }}
                                        className={styles.toggleOverview}
                                      >
                                        {/* {isOverviewExpanded
                                        ? ' See less'
                                        : ' See more'} */}
                                      </span>
                                    )}
                                </p>
                              )}
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                    <div className={styles.toggleButtonContainer}>
                      <button
                        onClick={toggleExpand}
                        className={styles.toggleButton}
                      >
                        <img
                          src={isExpanded ? arrowUp : arrowDown}
                          alt="Toggle"
                          className={styles.toggleArrow}
                        />
                      </button>
                    </div>
                  </div>
                </>
              )}

              {activeTab === 'moreLikeThis' && (
                <div className={styles.moreLikeThis}>
                  More Like This content
                </div>
              )}

              {activeTab === 'details' && (
                <div className={styles.details}>
                  {/* <div className={styles.overviewDetails}>
                    <ul>
                      <li>{series?.status}</li>

                      {series?.rating && (
                        <li>
                          <div className={styles.rating}>{series?.rating}</div>
                        </li>
                      )}
                      <li>{series?.seasons} Seasons</li>
                    </ul>
                  </div> */}
                  <div className={styles.bulletList}>
                    {series?.genres && (
                      <div>
                        <span className={styles.bulletText}>
                          {series.genres}
                        </span>
                      </div>
                    )}
                    {series?.languages && (
                      <div>
                        <div className={styles.bullet}></div>
                        <span className={styles.bulletText}>
                          {series.languages}
                        </span>
                      </div>
                    )}
                    {series?.countries && (
                      <div>
                        <div className={styles.bullet}></div>
                        <span className={styles.bulletText}>
                          {series.countries}
                        </span>
                      </div>
                    )}
                  </div>
                  <div
                    className={`${styles.castCrewContainer} ${isExpanded ? styles.expanded : ''
                      }`}
                  >
                    {series?.cast && series.cast.trim() && (
                      <div className={styles.column}>
                        <h2>Cast</h2>
                        <ul className={styles.columnContainer}>
                          {series.cast.split(',').map((actor, index) => (
                            <li key={index}>{actor.trim()}</li>
                          ))}
                        </ul>
                      </div>
                    )}
                    {series?.directors && series.directors.trim() && (
                      <div className={styles.column}>
                        <h2>Crew</h2>
                        <ul className={styles.columnContainer}>
                          {series.directors
                            .split(',')
                            .map((director, index) => (
                              <li key={index}>{director.trim()}</li>
                            ))}
                        </ul>
                      </div>
                    )}
                  </div>
                  <div className={styles.toggleButtonContainer}>
                    <button
                      onClick={toggleExpand}
                      className={styles.toggleButton}
                    >
                      <img
                        src={isExpanded ? arrowUp : arrowDown}
                        alt="Toggle"
                        className={styles.toggleArrow}
                      />
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TvShowDetails;
